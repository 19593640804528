var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"extra"},[(_vm.ready)?[(_vm.operation.operationType === _vm.TYPE.TRANSACTION_INTERNAL &&
        _vm.operation.side === _vm.constant.operations.OPERATION_SIDE.OUT &&
        _vm.operation.transactionMethodTypeEnum === _vm.constant.operations.TRANSACTION_METHOD_TYPE.CODE)?_c('TransactionInternalOut',{attrs:{"data":_vm.data}}):(_vm.operation.operationType === _vm.TYPE.TRANSACTION_INTERNAL &&
        _vm.operation.side === _vm.constant.operations.OPERATION_SIDE.IN &&
        _vm.operation.transactionMethodTypeEnum === _vm.constant.operations.TRANSACTION_METHOD_TYPE.CODE)?_c('TransactionInternalIn',{attrs:{"data":_vm.data}}):(_vm.operation.operationType === _vm.TYPE.REFERRAL)?_c('Referral',{attrs:{"data":_vm.data}}):(_vm.operation.operationType === _vm.TYPE.P2P)?_c('P2P',{attrs:{"data":_vm.data}}):(_vm.operation.operationType === _vm.TYPE.TRANSACTION_FEE ||
        _vm.operation.operationType === _vm.TYPE.TRANSACTION_INTERNAL_FEE)?_c('TransactionFee',{attrs:{"id":_vm.operation.id,"data":_vm.data}}):(_vm.operation.operationType === _vm.TYPE.FEE)?_c('Fee',{attrs:{"id":_vm.operation.id,"data":_vm.data}}):(_vm.operation.operationType === _vm.TYPE.P2P_FEE)?_c('P2pFee',{attrs:{"id":_vm.operation.id,"data":_vm.data}}):(_vm.operation.operationType === _vm.TYPE.TRANSACTION &&
        _vm.operation.side === _vm.constant.operations.OPERATION_SIDE.OUT)?_c('Transaction',{attrs:{"data":_vm.data,"history":_vm.history,"user":_vm.user,"transaction-method":_vm.transactionMethod},on:{"reload":_vm.getOperation,"openStatusModal":function($event){return _vm.$emit('openStatusModal')}}}):(_vm.operation.operationType === _vm.TYPE.TRANSACTION &&
        _vm.operation.side === _vm.constant.operations.OPERATION_SIDE.IN)?_c('Transaction',{attrs:{"data":_vm.data,"history":_vm.history,"user":_vm.user,"transaction-method":_vm.transactionMethod},on:{"reload":_vm.getOperation}}):_c('div',[(_vm.operation.transactionMethodType)?[_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.$t('Transaction method type:'))+" ")]),_vm._v(" "+_vm._s(_vm.$utils.getCaption( _vm.constant.operations.TRANSACTION_METHOD_TYPE_LIST, _vm.operation.transactionMethodTypeEnum ))+" ")]:_c('span',{staticClass:"overline"},[_vm._v(_vm._s(_vm.$t('No data')))])],2)]:_c('v-skeleton-loader',{attrs:{"boilerplate":"","type":"table-cell","max-width":"200px"}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }