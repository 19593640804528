//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    data: { type: Object, default: () => ({}) },
  },

  computed: {
    referralProgramId() {
      try {
        const { referralProgramId } = this.data.referral;
        return referralProgramId;
      } catch (error) {
        return '';
      }
    },

    referralProgramName() {
      try {
        const { referralProgramName } = this.data.referral;
        return referralProgramName;
      } catch (error) {
        return '';
      }
    },
  },
};
