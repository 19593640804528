//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    data: { type: Object, default: () => ({}) },
    id: { type: Number, default: 0 },
  },

  computed: {
    transactionId() {
      const { p2P } = this.data;
      if (p2P) {
        return p2P.tradeId;
      }
      return '';
    },
  },
};
