//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    data: { type: Object, default: () => ({}) },
    id: { type: Number, default: 0 },
  },
};
