//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import User from '~/components/User.vue';
import UserSelect from '~/components/UserSelect.vue';
import constant from '~/const';

export default {
  components: {
    User,
    UserSelect,
  },

  props: {
    data: { type: Object, default: () => ({}) },
  },

  data() {
    return {
      constant,
      redirectUserId: 0,
    };
  },

  computed: {
    receiver() {
      try {
        const { recipientId, recipientName } = this.data.transaction.code;
        return { recipientId, recipientName };
      } catch (error) {
        return {};
      }
    },

    transactionMethodType() {
      const { transactionMethodType } = this.data.transaction;
      return transactionMethodType;
    },
  },

  methods: {
    setRedirectUserId(value) {
      this.redirectUserId = value;
    },
  },
};
