//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex';
import TextField from '~/components/ui/TextField.vue';
import Select from '~/components/ui/Select.vue';
import DateRange from '~/components/ui/DateRange.vue';

import constant from '~/const';

export default {
  components: {
    TextField,
    Select,
    DateRange,
  },
  props: {
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      filters: {
        searchString: '',
        dateCreatedFrom: '',
        dateCreatedTo: '',
        operationTypeList: [],
        side: null,
        currencyIdList: [],
        cryptoCurrencyIdList: [],
        cryptoTokenIdList: [],
        transactionStatusList: [],
        transactionMethodIdList: [],
      },
      isFirstSearch: true,
      constant,
    };
  },

  computed: {
    ...mapState('transactionsHistory', ['filterLists']),
    ...mapState('currencies', ['currencies']),
    ...mapState('paymentsAndCurrenciesPayments', [
      'cryptoCurrencies',
      'cryptoTokens',
    ]),

    cryptoTokensFiltered() {
      const { cryptoTokens } = this;
      const { cryptoCurrencyIdList } = this.filters;
      return cryptoTokens.filter((e) => cryptoCurrencyIdList.includes(e.cryptoCurrencyId));
    },
  },

  watch: {
    filters: {
      async handler(data) {
        const { isFirstSearch } = this;
        const sortData = {
          sort: 'dateCreated',
          sortDesc: !Number(data.searchString),
        };

        await this.setSort(sortData);
        if (!isFirstSearch) {
          await this.setFilters(data);
          this.$emit('update');
        }
      },
      deep: true,
    },
  },

  mounted() {
    /* eslint-disable no-nested-ternary */
    const {
      historySearchString,
      historyOperationTypeList,
      historySide,
      historyTransactionStatusList,
    } = this.$route.query;
    const searchString = historySearchString;
    const operationTypeList = historyOperationTypeList
      ? typeof historyOperationTypeList === 'string'
        ? [historyOperationTypeList]
        : historyOperationTypeList
      : [];
    const side = historySide;
    const transactionStatusList = historyTransactionStatusList
      ? typeof historyTransactionStatusList === 'string'
        ? [historyTransactionStatusList]
        : historyTransactionStatusList
      : [];

    if (searchString || operationTypeList.length || side || transactionStatusList.length) {
      this.$router.replace({ query: {} });
      this.isFirstSearch = false;
      this.filters = {
        ...this.filters,
        searchString: searchString || '',
        operationTypeList: operationTypeList.map((e) => Number(e)),
        side: Number(side),
        transactionStatusList: transactionStatusList.map((e) => Number(e)),
      };
    } else {
      this.setFilters({});
    }
  },

  methods: {
    ...mapActions('transactionsHistory', ['setFilters', 'setSort']),

    async firstSearch() {
      const { filters } = this;

      this.isFirstSearch = false;
      await this.setFilters(filters);
      this.$emit('update');
    },
  },
};
