//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import User from '~/components/User.vue';
import constant from '~/const';

export default {
  components: { User },

  props: {
    data: { type: Object, default: () => ({}) },
  },

  data() {
    return {
      constant,
    };
  },

  computed: {
    relatedTransaction() {
      const { relatedTransaction } = this.data.transaction;
      return relatedTransaction;
    },

    transactionMethodType() {
      const { transactionMethodType } = this.data.transaction;
      return transactionMethodType;
    },

  },
};
